<template>
  <div class="box box_mm">
    <div class="inbox">
      <div
        style="color: #333; font-size: 35px; width: 100%; text-align: center"
      >
        政协第十五届西宁市委员会委员履职登记表
      </div>
      <div
        style="
          color: #333;
          font-size: 26px;
          width: 100%;
          text-align: center;
          margin-bottom: 30px;
          margin-top: 10px;
        "
      >
        2024年12月
      </div>
      <table>
        <tr>
          <td>姓名</td>
          <td>
            <el-input v-model="form.realname" placeholder="请输入"></el-input>
          </td>
          <td>性别</td>
          <td>
            <el-input v-model="form.gender" placeholder="请输入"></el-input>
          </td>
          <td>出身年月</td>
          <td>
            <el-date-picker
              v-model="form.birthday"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="请选择"
              style="width: 100%"
            >
            </el-date-picker>
          </td>
        </tr>
        <tr>
          <td>党派</td>
          <td>
            <el-input v-model="form.dangpai" placeholder="请输入"></el-input>
          </td>
          <td>界别</td>
          <td>
            <el-select v-model="form.jiebie" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.id"
                :label="item.name"
                :value="item.name"
              >
              </el-option>
            </el-select>
          </td>
          <td>联系电话</td>
          <td>
            <el-input v-model="form.mobile" placeholder="请输入"></el-input>
          </td>
        </tr>
        <tr>
          <td>现工作单位及职务</td>
          <td colspan="5">
            <el-input
              v-model="form.worker_zhiwu"
              placeholder="请输入"
            ></el-input>
          </td>
        </tr>
        <tr>
          <td style="height: 100px">社会兼职情况</td>
          <td colspan="5">
            <el-input
              type="textarea"
              :rows="4"
              placeholder="请输入"
              v-model="form.shjz_info"
            >
            </el-input>
          </td>
        </tr>
        <tr>
          <td style="height: 440px">
            履职小结 （“双岗双责、 双作为”及“五 个一”履职活动 情况等）
          </td>
          <td colspan="5">
            <div style="width: 100%; height: 100%">
              <div
                v-for="(item, index) in form.form_summarize"
                class="tcinput"
                style="
                  display: flex;
                  align-items: center;
                  border-bottom: 1px solid #eee;
                  margin: 10px;
                "
              >
                {{ index + 1 }}.
                <el-input v-model="item.value" placeholder="请输入"></el-input>
              </div>
              <div
                @click="addlzxj"
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 100%;
                  color: #999;
                  margin-top: 15px;
                  cursor: pointer;
                "
              >
                <i
                  class="el-icon-circle-plus-outline"
                  style="margin-right: 10px"
                ></i>
                继续添加
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td rowspan="2">参加省、市政 协会议、培训活 动情况</td>
          <td>全体会议（次）</td>
          <td>常委会议（次）</td>
          <td>双月协商、专题 议政会议（次）</td>
          <td>专委会议（次）</td>
          <td>培训、学习 （次）</td>
        </tr>
        <tr>
          <td>
            <el-input
              v-model="form.join_number1"
              placeholder="请输入"
            ></el-input>
          </td>
          <td>
            <el-input
              v-model="form.join_number2"
              placeholder="请输入"
            ></el-input>
          </td>
          <td>
            <el-input
              v-model="form.join_number3"
              placeholder="请输入"
            ></el-input>
          </td>
          <td>
            <el-input
              v-model="form.join_number4"
              placeholder="请输入"
            ></el-input>
          </td>
          <td>
            <el-input
              v-model="form.join_number5"
              placeholder="请输入"
            ></el-input>
          </td>
        </tr>
        <tr>
          <td style="height: 170px">
            会议发言（含大 会、双月协商、 专题性议政会议 及专委会议等）
          </td>
          <td colspan="5">
            <div style="width: 100%; height: 100%">
              <div
                v-for="(item, index) in form.meeting_speak"
                class="tcinput"
                style="
                  display: flex;
                  align-items: center;
                  border-bottom: 1px solid #eee;
                  margin: 10px;
                "
              >
                {{ index + 1 }}.
                <el-input v-model="item.value" placeholder="请输入"></el-input>
              </div>
              <div
                @click="addhyfy"
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 100%;
                  color: #999;
                  margin-top: 15px;
                  cursor: pointer;
                "
              >
                <i
                  class="el-icon-circle-plus-outline"
                  style="margin-right: 10px"
                ></i>
                继续添加
              </div>
            </div>
            <div style="color: red; margin-top: 15px; font-size: 0.16rem">
              请注明会议名称及发言题目
            </div>
          </td>
        </tr>
        <tr>
          <td rowspan="2">参加省、市政 协视察、调研 等活动</td>
          <td colspan="2">调研</td>
          <td colspan="2">视察</td>
          <td>其他</td>
        </tr>
        <tr>
          <td colspan="2">
            <div style="width: 100%; height: 100%">
              <div
                v-for="(item, index) in form.diaoyan"
                class="tcinput"
                style="
                  display: flex;
                  align-items: center;
                  border-bottom: 1px solid #eee;
                  margin: 10px;
                "
              >
                {{ index + 1 }}.
                <el-input v-model="item.value" placeholder="请输入"></el-input>
              </div>
              <div
                @click="adddy"
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 100%;
                  color: #999;
                  margin-top: 15px;
                  cursor: pointer;
                "
              >
                <i
                  class="el-icon-circle-plus-outline"
                  style="margin-right: 10px"
                ></i>
                继续添加
              </div>
            </div>
          </td>
          <td colspan="2">
            <div style="width: 100%; height: 100%">
              <div
                v-for="(item, index) in form.shicha"
                class="tcinput"
                style="
                  display: flex;
                  align-items: center;
                  border-bottom: 1px solid #eee;
                  margin: 10px;
                "
              >
                {{ index + 1 }}.
                <el-input v-model="item.value" placeholder="请输入"></el-input>
              </div>
              <div
                @click="addsc"
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 100%;
                  color: #999;
                  margin-top: 15px;
                  cursor: pointer;
                "
              >
                <i
                  class="el-icon-circle-plus-outline"
                  style="margin-right: 10px"
                ></i>
                继续添加
              </div>
            </div>
          </td>
          <td>
            <div style="width: 100%; height: 100%">
              <div
                v-for="(item, index) in form.other"
                class="tcinput"
                style="
                  display: flex;
                  align-items: center;
                  border-bottom: 1px solid #eee;
                  margin: 10px;
                "
              >
                {{ index + 1 }}.
                <el-input v-model="item.value" placeholder="请输入"></el-input>
              </div>
              <div
                @click="addqt"
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 100%;
                  color: #999;
                  margin-top: 15px;
                  cursor: pointer;
                "
              >
                <i
                  class="el-icon-circle-plus-outline"
                  style="margin-right: 10px"
                ></i>
                继续添加
              </div>
            </div>
          </td>
        </tr>

        <tr>
          <td rowspan="2">提案、意见建 议及社情民意</td>
          <td colspan="4">内容</td>
          <td>立案/采用情况</td>
        </tr>
        <tr>
          <td colspan="4">
            <el-input
              type="textarea"
              :rows="4"
              placeholder="请输入"
              v-model="form.tan_content"
            >
            </el-input>
          </td>
          <td>
            <el-input
              type="textarea"
              :rows="4"
              placeholder="请输入"
              v-model="form.tan_la_cq_info"
            >
            </el-input>
          </td>
        </tr>
        <tr>
          <td style="height: 200px">
            加强与委员沟通交流、联系界别群众、发挥凝聚共识作用情况
          </td>
          <td colspan="5">
            <el-input
              type="textarea"
              :rows="4"
              placeholder="请输入"
              v-model="form.zyqk"
            >
            </el-input>
            <div style="color: red; margin-top: 15px; font-size: 0.16rem">
              该项仅常委填写
            </div>
          </td>
        </tr>
        <tr>
          <td style="height: 200px">
            撰写关于政协理论学习文章、文史资料等情况
          </td>
          <td colspan="5">
            <el-input
              type="textarea"
              :rows="4"
              placeholder="请输入"
              v-model="form.wszl"
            >
            </el-input>
          </td>
        </tr>
        <tr>
          <td style="height: 200px">获得荣誉、奖 惩情况</td>
          <td colspan="5">
            <div style="width: 100%; height: 100%">
              <div
                v-for="(item, index) in form.honor_punish_content"
                class="tcinput"
                style="
                  display: flex;
                  align-items: center;
                  border-bottom: 1px solid #eee;
                  margin: 10px;
                "
              >
                {{ index + 1 }}.
                <el-input v-model="item.value" placeholder="请输入"></el-input>
                <el-upload
                  class="upload-demo"
                  :action="baseurl + '/api/login/upload'"
                  multiple
                  :on-success="
                    (response) => uploadsuccessfujian(response, index)
                  "
                  :show-file-list="false"
                >
                  <el-button size="mini" type="primary">{{
                    item.fileurl ? "重新上传" : "上传证明"
                  }}</el-button>
                </el-upload>
                <el-button
                  type="success"
                  size="mini"
                  v-show="item.fileurl"
                  @click="openurl(item.fileurl)"
                  >查看</el-button
                >
              </div>
              <div
                @click="addryjf"
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 100%;
                  color: #999;
                  margin-top: 15px;
                  cursor: pointer;
                "
              >
                <i
                  class="el-icon-circle-plus-outline"
                  style="margin-right: 10px"
                ></i>
                继续添加
              </div>
            </div>
          </td>
        </tr>
        <!-- <tr>
					<td style="height: 160px;">
						所在单位意见
					</td>
					<td colspan="5">
						{{form.unit_opinion}}
					</td>
				</tr> -->
        <tr>
          <td style="height: 160px">所在专门委员会意见</td>
          <td colspan="5">
            <div
              style="
                justify-content: flex-start;
                align-items: center;
                text-align: left;
                display: flex;
              "
            >
              <div style="width: 75%">
                {{ form.spec_comm_opinion }}
              </div>
              <img
                v-if="form.shenghe_user"
                :src="form.shenghe_user.fullurl"
                alt=""
                style="width: 20%; height: auto; margin-left: 5%"
              />
            </div>
          </td>
        </tr>
        <tr>
          <td rowspan="2">评审意见</td>
          <td colspan="2">优秀</td>
          <td colspan="2">称职</td>
          <td>不称职</td>
        </tr>
        <tr>
          <td colspan="2" style="height: 50px">
            <i
              v-show="form.evaluate == '优秀'"
              class="el-icon-check"
              style="font-size: 24px; color: #333; font-weight: bold"
            ></i>
          </td>
          <td colspan="2">
            <i
              v-show="form.evaluate == '称职'"
              class="el-icon-check"
              style="font-size: 24px; color: #333; font-weight: bold"
            ></i>
          </td>
          <td>
            <i
              v-show="form.evaluate == '不称职'"
              class="el-icon-check"
              style="font-size: 24px; color: #333; font-weight: bold"
            ></i>
          </td>
        </tr>
      </table>

      <div
        style="
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 20px;
        "
      >
        <!-- <el-button @click="$router.go(-1)">取消</el-button> -->
        <el-button type="primary" @click="submite(0)" v-show="showpost"
          >保存</el-button
        >
        <el-button type="success" @click="submite(1)" v-show="showpost"
          >提交</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      baseurl: "",
      form: {
        realname: "",
        gender: "",
        birthday: "",
        dangpai: "",
        jiebie: "",
        mobile: "",
        worker_zhiwu: "",
        shjz_info: "",
        join_number1: "",
        join_number2: "",
        join_number3: "",
        join_number4: "",
        join_number5: "",
        tan_content: "",
        tan_la_cq_info: "",
        zyqk: "",
        wszl: "",
        form_summarize: [
          {
            value: "",
          },
        ],
        meeting_speak: [
          {
            value: "",
          },
        ],
        diaoyan: [
          {
            value: "",
          },
        ],
        shicha: [
          {
            value: "",
          },
        ],
        other: [
          {
            value: "",
          },
        ],
        honor_punish_content: [{ value: "", fileids: "", fileurl: "" }],
      },
      options: [],
      showpost: true,
    };
  },
  created() {
    this.baseurl = this.$URL;
    this.$post({
      url: "/api/user_performance_form/get_performance_form",
      params: {},
    }).then((res) => {
      console.log(res);
      if (res) {
        if (res.apply_status == 0 && res.status != 0) {
          this.$alert("您的履职表正在审核中...", "提示", {
            confirmButtonText: "确定",
            callback: (action) => {},
          });
        }
        if (res.apply_status == 2 && res.status != 0) {
          this.$alert(res.refund_text, "驳回提示", {
            confirmButtonText: "确定",
            callback: (action) => {},
          });
          this.showpost = true;
        }
        if (res.status == 0) {
          this.showpost = true;
        }
        res.diaoyan = res.diaoyan ? JSON.parse(res.diaoyan) : [{ value: "" }];
        res.form_summarize = res.form_summarize
          ? JSON.parse(res.form_summarize)
          : [{ value: "" }];
        res.honor_punish_content = res.honor_punish_content
          ? JSON.parse(res.honor_punish_content)
          : [{ value: "", fileids: "", fileurl: "" }];
        res.meeting_speak = res.meeting_speak
          ? JSON.parse(res.meeting_speak)
          : [{ value: "" }];
        res.other = res.other ? JSON.parse(res.other) : [{ value: "" }];
        res.shicha = res.shicha ? JSON.parse(res.shicha) : [{ value: "" }];
        this.form = res;
      } else {
        this.showpost = true;
      }
    });

    this.$post({
      url: "/api/circles/index",
      params: {},
    }).then((res) => {
      this.options = res.list;
    });
  },
  methods: {
    uploadsuccessfujian(response, index) {
      this.$message.success("上传成功");
      this.form.honor_punish_content[index].fileids = response.id;
      this.form.honor_punish_content[index].fileurl = response.fullurl;
    },
    openurl(url) {
      window.open(url, "_blank");
    },
    submite(i) {
      this.form.honor_punish_content = this.form.honor_punish_content.filter(
        (item) => item.value
      );
      this.form.other = this.form.other.filter((item) => item.value);
      this.form.shicha = this.form.shicha.filter((item) => item.value);
      this.form.diaoyan = this.form.diaoyan.filter((item) => item.value);
      this.form.meeting_speak = this.form.meeting_speak.filter(
        (item) => item.value
      );
      this.form.form_summarize = this.form.form_summarize.filter(
        (item) => item.value
      );

      let falge = false;
      this.form.honor_punish_content.forEach((item, index) => {
        if (item.value && !item.fileids) {
          falge = true;
        }
      });
      if (falge) {
        this.$message.warning("请上传证明");
        return;
      }

      this.form.status = i;
      if (i == 0) {
        //草稿
        this.$post({
          url: "/api/user_performance_form/submit_performance_form",
          params: this.form,
        }).then((res) => {
          this.$message.success("保存成功");
        });
      } else {
        //提交
        if (
          !this.form.realname ||
          !this.form.gender ||
          !this.form.birthday ||
          !this.form.dangpai ||
          !this.form.jiebie ||
          !this.form.mobile
        ) {
          this.$message.warning("请完整填写基本资料");
          return;
        }
        if (!this.form.worker_zhiwu) {
          this.$message.warning("请填写现工作单位及职务");
          return;
        }
        if (!this.form.shjz_info) {
          this.$message.warning("请填写社会兼职情况");
          return;
        }
        if (this.form.form_summarize.length==0||!this.form.form_summarize[0].value) {
          this.$message.warning("请填写履职小结");
          return;
        }
        if (this.form.meeting_speak.length==0||!this.form.meeting_speak[0].value) {
          this.$message.warning("请填写会议发言");
          return;
        }

        if (this.form.diaoyan.length==0||!this.form.diaoyan[0].value) {
          this.$message.warning("请填写调研");
          return;
        }
        if (this.form.shicha.length==0||!this.form.shicha[0].value) {
          this.$message.warning("请填写视察");
          return;
        }
        if (this.form.other.length==0||!this.form.other[0].value) {
          this.$message.warning("请填写其他");
          return;
        }
        if (this.form.honor_punish_content.length==0||!this.form.honor_punish_content[0].value) {
          this.$message.warning("请填写获得荣誉、奖惩情况");
          return;
        }
        this.$post({
          url: "/api/user_performance_form/submit_performance_form",
          params: this.form,
        }).then((res) => {
          this.$message.success("提交成功");
          this.$router.go(-1);
        });
      }
    },

    addlzxj() {
      this.$set(this.form.form_summarize, this.form.form_summarize.length, {
        value: "",
      });
    },
    addhyfy() {
      this.$set(this.form.meeting_speak, this.form.meeting_speak.length, {
        value: "",
      });
    },
    addqt() {
      this.$set(this.form.other, this.form.other.length, {
        value: "",
      });
    },
    addsc() {
      this.$set(this.form.shicha, this.form.shicha.length, {
        value: "",
      });
    },
    adddy() {
      this.$set(this.form.diaoyan, this.form.diaoyan.length, {
        value: "",
      });
    },
    addryjf() {
      this.$set(
        this.form.honor_punish_content,
        this.form.honor_punish_content.length,
        {
          value: "",
          fileids: "",
          fileurl: "",
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "index.scss";
</style>
